import React, { useState, useEffect } from 'react';
import axios from 'axios';

import '../../assets/style.css';

import cashIcon from '../../assets/sprite_0.png';
import keyIcon from '../../assets/items/key.png';
import tokenIcon from '../../assets/items/adventoken.png';

import zone01 from '../../assets/01_Clucky.png';
import zone02 from '../../assets/02_Garly.png';
import zone03 from '../../assets/03_Crabby.png';
import zone04 from '../../assets/04_Chilly.png';
import zone05 from '../../assets/05_Prawnie.png';
import zone06 from '../../assets/06_Beany.png';
import zone07 from '../../assets/07_Piggy.png';
import zone08 from '../../assets/08_Salty.png';
import zone09 from '../../assets/09_Fishy.png';
import zone10 from '../../assets/10_Tommatoes.png';

import drop01Icon from '../../assets/ingredients/01_Chicken.png';
import drop02Icon from '../../assets/ingredients/02_Garlic.png';
import drop03Icon from '../../assets/ingredients/03_Crab.png';
import drop04Icon from '../../assets/ingredients/04_Chilli.png';
import drop05Icon from '../../assets/ingredients/05_Prawn.png';
import drop06Icon from '../../assets/ingredients/06_BeanSprouts.png';
import drop07Icon from '../../assets/ingredients/07_PorkRibs.png';
import drop08Icon from '../../assets/ingredients/08_SaltedVegetable.png';
import drop09Icon from '../../assets/ingredients/09_Fish.png';
import drop10Icon from '../../assets/ingredients/10_Tomato.png';

function AdventureCard({ id, zone, idleDMG, timePerKill, timeElapsed, maxTime, stats, onJoin, onClaim, onExit }) {

    let zoneNames = ["Unavailable", "Zone 1 - Clucky", "Zone 2 - Garly", "Zone 3 - Crabby", "Zone 4 - Chilly"];
    let zoneIcon = ["Unavailable", zone01, zone02, zone03, zone04];
    let zoneDrop = ["Unavailable", drop01Icon, drop02Icon, drop03Icon, drop04Icon];
    let zoneHealth = [0, 25, 25, 100, 100];
    let zoneDefence = [0, 1, 1, 5, 5];

    const [username] = useState(() => {
        return sessionStorage.getItem('username');
    });

    // Update the data at initial load (Once)
    function timeFormatter(seconds) {
        if (seconds < 60) {
            return "0:" + ((seconds < 10) ? "0" + seconds : seconds);
        } else if (seconds < (3600)) {
            return Math.floor(seconds / 60) + ":" + ((seconds % 60 < 10) ? "0" + seconds % 60 : seconds % 60);
        } else if (seconds < maxTime) {
            return Math.floor(seconds / 3600) + ":" + (((Math.floor(seconds % 3600 / 60)) < 10) ? "0" + Math.floor(seconds % 3600 / 60) : Math.floor(seconds % 3600 / 60)) + ":" + ((seconds % 60 < 10) ? "0" + seconds % 60 : seconds % 60);
        } else {
            return Math.floor(maxTime / 3600) + ":00:00";
        }
    }

    function timeString(seconds) {
        if (seconds < 60) {
            return seconds + "s";
        } else if (seconds < 3600) {
            return Math.round(seconds / 60) + "m";
        } else {
            return Math.round(seconds / 3600) + "h";
        }
    }

    return (
        <div className="adventureCard">
            <h1 className="adventureCard-zoneHeader">{zoneNames[id]}</h1>
            <div className="adventureCard-zoneDetail top-margin-10">
                <img className="adventureCard-enemyIcon" src={zoneIcon[id]} alt="Home" />
                <div className="adventureCard-zoneInfo">
                    <h2 className="top-margin-10">Statistics</h2>
                    <h3>Health: {zoneHealth[id]}</h3>
                    <h3 className="adventurePage-zoneCardInfo-enemyInfoText">Defence: {zoneDefence[id]}</h3>
                    <h3 className="adventurePage-zoneCardInfo-enemyInfoText">Total Killed: <b>{stats}</b></h3>
                    <h2 className="top-margin-10">Drops</h2>
                    <img className="adventureCard-zoneDropIcon" src={cashIcon} alt="Home" />
                    <img className="adventureCard-zoneDropIcon" src={zoneDrop[id]} alt="Home" />
                    <img className="adventureCard-zoneDropIcon" src={keyIcon} alt="Home" />
                    <img className="adventureCard-zoneDropIcon" src={tokenIcon} alt="Home" />
                </div>
            </div>
            <div className="gridCard-CTA top-margin-10">
                {(zone === 0) ? <><div className="adventureCard-zoneFooter"><h4>DMG Required:</h4><h4><b>{idleDMG} / {zoneDefence[id]}+</b></h4></div><button className="gridCard-Button" onClick={() => onJoin()} type="button">Enter</button></> : ""}
                {(zone === id && timePerKill > 0) ? <><div className="adventureCard-zoneFooter"><h4>Idle Time: <b>{timeFormatter(timeElapsed)} / {timeFormatter(maxTime)}</b></h4><h4>Total Kills: <b>{Math.floor(((timeElapsed >= maxTime) ? maxTime : timeElapsed) / timePerKill)}</b> (1 in <b>{timeString(timePerKill)}</b>)</h4></div><button className="gridCard-Button" onClick={() => onClaim()} type="button">Claim</button></> : ""}
                {(zone === id && timePerKill <= 0) ? <><div className="adventureCard-zoneFooter"><h4><b>Low Damage for Adventure!</b></h4><h4>Improve your damage with <b>Equipment</b> and <b>Gems</b> first!</h4></div><button className="gridCard-Button" onClick={() => onExit()} type="button">Exit</button></> : ""}
                {(zone !== 0 && zone !== id) ? <><div className="adventureCard-zoneFooter"><h4>You have an active adventure.</h4><h4>Claim and exit before entering another zone!</h4></div></> : ""}
            </div>
        </div>
    );
}

export default AdventureCard;

