import React from 'react';

import homeIcon from '../../assets/logo.png';
import inventoryIcon from '../../assets/bag.png';
import kitchenIcon from '../../assets/sprite_3.png';
import battleIcon from '../../assets/tool1.png';
import profileIcon from '../../assets/sprite_2.png';

import './navBar.css';

function NavBar() {
  return (
    <div className="navBar">
       <a href="/market" className="navButton">
        <img className="navButton_Icon" src={kitchenIcon} alt="Home" />
        <div className="navButton_Text">Market</div>
      </a>
      <a href="/equipment" className="navButton">
        <img className="navButton_Icon" src={battleIcon} alt="Home" />
        <div className="navButton_Text">Equip</div>
      </a>
      <a href="/home" className="navButton">
        <img className="navButton_Icon" src={homeIcon} alt="Home" />
        <div className="navButton_Text">Home</div>
      </a>
      <a href="/battle" className="navButton">
        <img className="navButton_Icon" src={battleIcon} alt="Home" />
        <div className="navButton_Text">Battle</div>
      </a>
      {/* <a href="/inventory" className="navButton">
        <img className="navButton_Icon" src={inventoryIcon} alt="Home" />
        <div className="navButton_Text">Inventory</div>
      </a> */}
      <a href="/kitchen" className="navButton">
        <img className="navButton_Icon" src={profileIcon} alt="Home" />
        <div className="navButton_Text">Kitchen</div>
      </a>
    </div>
  );
}

export default NavBar;